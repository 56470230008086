<template>
  <section class="struct-table">
    <article v-for="(param, i) in params" :key="i" class="flex-box">
      <p class="struct-info">{{ param.name }}</p>
      <div
        v-if="
          Object.prototype.toString.call(param.value) === '[object Array]' &&
          param.value.length > 0
        "
        class="flex-1"
      >
        <main
          v-for="(calls, index) in param.value"
          :key="index"
          class="flex-box"
        >
          <p class="struct-info">{{ index }}</p>
          <ul class="flex-1">
            <li v-for="(value, key) in calls" :key="key" class="flex-box">
              <p class="struct-info">{{ key }}</p>
              <struct-array
                :values="value"
                v-if="
                  Object.prototype.toString.call(value) === '[object Array]' &&
                  value.length > 0
                "
              />
              <struct-object
                :values="value"
                v-else-if="
                  Object.prototype.toString.call(value) === '[object Object]'
                "
              />
              <p v-else class="struct-info">{{ value }}</p>
            </li>
          </ul>
        </main>
      </div>
      <main
        class="flex-1"
        v-else-if="
          Object.prototype.toString.call(param.value) === '[object Object]'
        "
      >
        <div v-for="(value, key) in param.value" :key="key" class="flex-box">
          <p class="struct-info">{{ key }}</p>
          <struct-array
            :values="value"
            v-if="
              Object.prototype.toString.call(value) === '[object Array]' &&
              value.length > 0
            "
          />
          <struct-object
            :values="value"
            v-else-if="
              Object.prototype.toString.call(value) === '[object Object]'
            "
          />
          <p v-else class="struct-info">{{ value }}</p>
        </div>
      </main>
      <struct-address
        class="struct-info"
        v-else-if="param.type == 'Address'"
        :address="param.value"
      />
      <!-- token 数量 -->
      <struct-balance
        v-else-if="param.type.includes('Balance')"
        :balance="param.value"
      />
      <p class="struct-info" v-else>{{ param.value }}</p>
    </article>
  </section>
</template>
<script>
import structBalance from "./struct-balance.vue";
import structArray from "./struct-array.vue";
import structObject from "./struct-object.vue";
import StructAddress from "base/struct-address.vue";

export default {
  components: { structBalance, structArray, structObject, StructAddress },
  props: ["params"],
};
</script>
<style>
.struct-table .flex-box {
  padding: 0;
  width: 100%;
}

.struct-table > .flex-box {
  border-left: var(--border);
}
.struct-table > .flex-box:last-of-type {
  border-bottom: var(--border);
}

.struct-table .struct-info {
  border-top: var(--border);
  border-right: var(--border);
  width: 100%;
  min-width: 110px;
  padding: 12px;
  word-break: break-word;
}

.struct-info .struct-info {
  padding: 0 0 12px;
}

.struct-info:first-of-type {
  width: 150px;
}
.flex-1 {
  width: 100%;
}
.flex-box .struct-info:last-child{
  border-right:none;
}
</style>
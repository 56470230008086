<template>
  <p class="struct-info">
    {{ webUtil.formatNumber(balance, tokenDecimals) }}
  </p>
</template>
<script>
export default {
  props:['balance']
}
</script>
<style scoped>

</style>
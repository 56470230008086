<template>
  <div class="flex-1">
    <div class="flex-box" v-for="(value, key) in values" :key="key">
      <p class="struct-info">{{ key }}</p>
      <struct-address class="struct-info" v-if="key == 'address'" :address="value" />
      <!-- token 数量 -->
      <struct-balance
        v-else-if="key.includes('Balance')"
        :balance="value"
      />
      <p class="struct-info" v-else>{{ value }}</p>
    </div>
  </div>
</template>
<script>
import StructAddress from "base/struct-address.vue";
import StructBalance from "./struct-balance.vue";
export default {
  props: ["values"],
  components: { StructAddress , StructBalance},
};
</script>
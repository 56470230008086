var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"struct-table"},_vm._l((_vm.params),function(param,i){return _c('article',{key:i,staticClass:"flex-box"},[_c('p',{staticClass:"struct-info"},[_vm._v(_vm._s(param.name))]),(
        Object.prototype.toString.call(param.value) === '[object Array]' &&
        param.value.length > 0
      )?_c('div',{staticClass:"flex-1"},_vm._l((param.value),function(calls,index){return _c('main',{key:index,staticClass:"flex-box"},[_c('p',{staticClass:"struct-info"},[_vm._v(_vm._s(index))]),_c('ul',{staticClass:"flex-1"},_vm._l((calls),function(value,key){return _c('li',{key:key,staticClass:"flex-box"},[_c('p',{staticClass:"struct-info"},[_vm._v(_vm._s(key))]),(
                Object.prototype.toString.call(value) === '[object Array]' &&
                value.length > 0
              )?_c('struct-array',{attrs:{"values":value}}):(
                Object.prototype.toString.call(value) === '[object Object]'
              )?_c('struct-object',{attrs:{"values":value}}):_c('p',{staticClass:"struct-info"},[_vm._v(_vm._s(value))])],1)}),0)])}),0):(
        Object.prototype.toString.call(param.value) === '[object Object]'
      )?_c('main',{staticClass:"flex-1"},_vm._l((param.value),function(value,key){return _c('div',{key:key,staticClass:"flex-box"},[_c('p',{staticClass:"struct-info"},[_vm._v(_vm._s(key))]),(
            Object.prototype.toString.call(value) === '[object Array]' &&
            value.length > 0
          )?_c('struct-array',{attrs:{"values":value}}):(
            Object.prototype.toString.call(value) === '[object Object]'
          )?_c('struct-object',{attrs:{"values":value}}):_c('p',{staticClass:"struct-info"},[_vm._v(_vm._s(value))])],1)}),0):(param.type == 'Address')?_c('struct-address',{staticClass:"struct-info",attrs:{"address":param.value}}):(param.type.includes('Balance'))?_c('struct-balance',{attrs:{"balance":param.value}}):_c('p',{staticClass:"struct-info"},[_vm._v(_vm._s(param.value))])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="flex-1">
    <div class="flex-box" v-for="(item, index) in values" :key="index">
      <p class="struct-info">{{ item.name }}</p>
      <struct-address
        class="struct-info"
        v-if="item.type == 'Address'"
        :address="item.value"
      />
      <!-- token 数量 -->
      <struct-balance
        v-else-if="item.type.includes('Balance')"
        :balance="item.value"
      />
      <p class="struct-info" v-else>{{ item.value }}</p>
    </div>
  </div>
</template>
<script>
import StructAddress from "base/struct-address.vue";
import StructBalance from "./struct-balance.vue";
export default {
  props: ["values"],
  components: { StructAddress,StructBalance },
};
</script>
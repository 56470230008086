<template>
  <section class="container">
    <main class="card table-list">
      <h3>
        {{ $t("extrinsic") }}
        <span v-if="id">#{{ id }}</span>
      </h3>
      <!-- 交易记录详情 -->
      <template v-if="tx">
        <ul class="layout-about-item-label">
          <li v-if="tx.extrinsic_hash">
            <span>{{ $t("extrinsicHash") }}</span>
            <p>
              <i class="text-blue">{{ "0x" + tx.extrinsic_hash }}</i>
              <img
                class="copy-btn"
                @click="copyAction('0x' + tx.extrinsic_hash)"
                src="@/static/img/icon/copy_grey.png"
                width="16"
              />
            </p>
          </li>
          <li>
            <span>{{ $t("result") }}</span>
            <p class="text-green" v-if="tx.success">Success</p>
            <p class="text-orange" v-else>Fail ({{ tx.error_message }})</p>
          </li>
          <li>
            <span>{{ $t("block") }}</span>
            <router-link
              class="text-blue"
              :to="'/block/' + tx.block_id"
              >{{ tx.block_id }}</router-link
            >
          </li>
          <li>
            <span>{{ $t("time") }}</span>
            <p v-if="tx.datetime">
              {{ webUtil.timestampToDate(tx.datetime) }}
            </p>
          </li>
          <li>
            <span>{{ $t("module") }}</span>
            <p class="text-capitalize pink-bg-white-btn">
              {{ tx.module_id }}
            </p>
          </li>
          <li>
            <span>{{ $t("call") }}</span>
            <p class="text-capitalize grey-bg-pink-btn">{{ tx.call_id }}</p>
          </li>
          <li v-if="tx.address">
            <span>{{ $t("sender") }}</span>
            <struct-address :address="tx.address" />
          </li>
          <!-- 转账 call=transfer-->
          <template v-if="transfer">
            <li>
              <span>{{ $t("destination") }}</span>
              <struct-address :address="transfer.to" />
            </li>
            <li>
              <span>{{ $t("value") }}</span>
              <p>{{ transfer.value }} {{ tokenSymbol }}</p>
            </li>
          </template>

          <li v-if="tx.fee > 0">
            <span>{{ $t("fee") }}</span>
            <p>
              {{ webUtil.formatNumber(tx.fee, tokenDecimals) }}
              {{ tokenSymbol }}
            </p>
          </li>
        </ul>
        <ul class="layout-about-item-label second">
          <li v-if="tx.nonce">
            <span>{{ $t("nonce") }}</span>
            <p>{{ tx.nonce }}</p>
          </li>
          <li v-if="tx.params && tx.params.length > 0">
            <span>{{ $t("parameters") }}</span>
            <div class="params">
              <p class="flex-end-center">
                <button class="pink-bg-white-btn" @click="copyAction(formatParams)">{{$t('Copy')}}</button>
                <button v-show="!showCode" class="pink-bg-white-btn" @click="showCode=true">{{$t('ViewCode')}}</button>
                <button v-show="showCode" class="pink-bg-white-btn" @click="showCode=false">{{$t('Decode')}}</button>
              </p>
              <ace-editor v-show="showCode" lang="json" :code="formatParams"/>
              <struct-table :params="tx.params" v-show="!showCode"/>
            </div>
          </li>
          <li v-if="tx.signature">
            <span>{{ $t("signature") }}</span>
            <p class="struct-table pd-12">{{ tx.signature }}</p>
          </li>
        </ul>
      </template>
      <div class="null" v-else>{{ $t("noInformation") }}</div>
    </main>

    <!-- events -->
    <main class="card table-list" v-if="events && events.length > 0">
      <h3>
        {{ $t("events") }}
        <span class="text-12">{{ events.length }}</span>
      </h3>
      <div class="table-responsive">
        <table>
          <thead>
            <tr>
              <th>{{ $t("eventID") }}</th>
              <th v-if="tx.extrinsic_hash">{{ $t("hash") }}</th>
              <th class="text-left">{{ $t("action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in events" :key="item.id">
              <td>{{ item.id ? item.id : "--" }}</td>
              <td v-if="tx.extrinsic_hash">
                {{ webUtil.formatStrByDot("0x" + tx.extrinsic_hash) }}
              </td>
              <td v-if="item.attributes.module_id" class="text-left">
                <span class="type">{{ item.attributes.module_id }}({{
                  item.attributes.event_id
                }})</span>
              </td>
              <td v-else class="text-left">--</td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
    <loading :loading="txLoading"></loading>
  </section>
</template>

<script>
import loading from "base/loading";
import StructTable from "./struct-table.vue";
import StructAddress from "base/struct-address.vue";
import AceEditor from 'base/ace-editor';
export default {
  props: ["txID"],
  data() {
    return {
      id: "",
      tx: null,
      events: [],
      transfer: null,
      txLoading: true,
      showCode:false,
      options:{
        maxLines: 30,
        minLines: 2,
        readOnly: true,
        printMargin:false,
      }
    };
  },
  created() {
    this.getExtrinsic();
  },
  computed:{
    formatParams(){
      if(this.tx&&this.tx.params){
        return JSON.stringify(this.tx.params,null,2)
      }
      return '';
    }
  },
  methods: {
    getExtrinsic() {
      this.txLoading = true;
      this.axios
        .get(this.domain + "v1/extrinsic/" + this.txID + "?include=events")
        .then((res) => {
          this.txLoading = false;
          this.tx = res.data.data.attributes;
          this.id = res.data.data.id;
          this.events = res.data.included;
          this.getTransferInfo();
        })
        .catch((e) => {
          console.log(e);
          this.txLoading = false;
        });
    },
    // 获取转账信息
    getTransferInfo() {
      if (
        this.tx.call_id.toLowerCase().includes("transfer") &&
        this.events &&
        this.events.length > 0
      ) {
        let event = this.events.filter((event) =>
          event.attributes.event_id.toLowerCase().includes("transfer")
        );
        if (event && event.length > 0) {
          let trans = event[0].attributes.attributes;
          let fee = event[0].attributes.balancetransfer.fee;
          this.transfer = {
            from: trans[0].value,
            to: trans[1].value,
            fee: fee?this.webUtil.formatNumber(fee,this.tokenDecimals):0,
            value: this.webUtil.formatNumber(
              trans[2].value,
              this.tokenDecimals
            ),
          };
        }
      }
    },
  },
  components: {
    loading,
    StructTable,
    StructAddress,
    AceEditor,
  },
  watch: {
    txID(to) {
      this.getExtrinsic();
    },
  },
};
</script>

<style scoped>
a {
  vertical-align: middle;
}
.params{
  width:100%;
}
.params .flex-end-center{
  padding-bottom: 10px;
}
.params button:first-child{
  margin-right: 10px;
}
@media(max-width:768px){
  .params .flex-end-center{
    justify-content: flex-start;
  }
}
</style>
